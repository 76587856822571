import { createApi } from "@reduxjs/toolkit/query/react";
import { customFetchBase } from "../customFetchBase";
import _ from "lodash";
import queryString from "query-string";

const BASE = "/api/blog";

export const adminApproveBlog = createApi({
    reducerPath: "adminApproveBlog",
    keepUnusedDataFor: 30,
    tagTypes: ["Blog"],
    baseQuery: customFetchBase,
    endpoints(builder) {
        return {
            getListBlog: builder.query<Array<any>, void>({
                query: (query) => ({
                    url: `${BASE}`,
                    method: "GET",
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                providesTags: (result, error, page) =>
                    result
                        ? [
                              ...result.map(({ id }) => ({ type: "Blog" as const, id })),
                              { type: "Blog", id: "Blog-LIST" },
                          ]
                        : [{ type: "Blog", id: "Blog-LIST" }],
            }),
            getListBlogPaginate: builder.query<IResponseDataAdmin<any>, any>({
                query: (query) => ({
                    url: `${BASE}/paginate?` + queryString.stringify(query, { arrayFormat: "bracket" }),
                    method: "GET",
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                providesTags: (result, error, page) =>
                    result
                        ? [
                              ...result.items.map(({ id }) => ({ type: "Blog" as const, id })),
                              { type: "Blog", id: "Blog-LIST" },
                          ]
                        : [{ type: "Blog", id: "Blog-LIST" }],
            }),
            getBlogById: builder.query<IBlog, { id: string }>({
                query: (param) => ({
                    url: `${BASE}/${param.id}`,
                    method: "GET",
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
            }),
            createBlog: builder.mutation<any, any>({
                query: (params) => ({
                    url: `${BASE}`,
                    method: "POST",
                    body: params,
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["Blog"],
            }),
            updateBlog: builder.mutation<any, IBlog>({
                query: (params) => ({
                    url: `${BASE}/${params.id}`,
                    method: "PATCH",
                    body: params,
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["Blog"],
            }),
            updateApproveBlog: builder.mutation<any, any>({
                query: (params) => ({
                    url: `${BASE}/approved/${params.id}`,
                    method: "PATCH",
                    body: _.omit(params, ["id"]),
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["Blog"],
            }),
            updateSchoolTags: builder.mutation<any, any>({
                query: (params) => ({
                    url: `/api/user/blog/school-tags/${params.id}`,
                    method: "PATCH",
                    body: _.omit(params, ["id"]),
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["Blog"],
            }),
            deleteBlog: builder.mutation<any, { id: string }>({
                query: (params) => ({
                    url: `${BASE}/${params.id}`,
                    method: "DELETE",
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["Blog"],
            }),
            deleteMultiBlog: builder.mutation<any, { ids: Array<string> }>({
                query: (params) => ({
                    url: `${BASE}`,
                    method: "DELETE",
                    body: {
                        ids: params.ids,
                    },
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["Blog"],
            }),
        };
    },
});

export const {
    useGetListBlogPaginateQuery,
    useGetListBlogQuery,
    useLazyGetListBlogPaginateQuery,
    useLazyGetListBlogQuery,
    useUpdateApproveBlogMutation,
    useUpdateSchoolTagsMutation,
    useGetBlogByIdQuery,
    useLazyGetBlogByIdQuery,
    useCreateBlogMutation,
    useDeleteBlogMutation,
    useDeleteMultiBlogMutation,
    useUpdateBlogMutation,
} = adminApproveBlog;
