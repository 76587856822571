import blankImage from "@assets/images/blank.png";
import { useToast } from "@components/toast/ToastProvider";
import { TOAST_DELETE_SUCCESS, TOAST_UPLOAD_ERROR, TOAST_UPLOAD_SUCCESS } from "@consts";
import store from "@redux/store";
import { message, Upload } from "antd";
import { RcFile, UploadFile } from "antd/es/upload";
import { UploadChangeParam } from "antd/lib/upload";
import { useEffect, useState } from "react";

interface Props {
    setThumbnail: (thumbnail: any) => void;
    editThumbnail: any;
}

const UploadImg = (props: Props) => {
    const { setThumbnail, editThumbnail } = props;
    const { showToast } = useToast();

    const token = store.getState().auth.accessToken;
    const type = store.getState().auth.type;

    const [fileList, setFileList] = useState<UploadFile[]>([]);

    const handleImageUpload = (blobInfo: any, progress: any, failure: any) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("POST", `${process.env.REACT_APP_HOST}/api/file/upload/original`, true);
            xhr.setRequestHeader("Authorization", `${type} ${token}`);

            const formData = new FormData();
            formData.append("file", blobInfo.blob(), blobInfo.filename());

            xhr.upload.onprogress = (e) => {
                progress((e.loaded / e.total) * 100);
                if (progress && typeof progress === "function") {
                    const percent = 0;
                    progress(percent);
                }
            };

            xhr.onload = () => {
                if (xhr.status === 403) {
                    reject({ message: "HTTP Error: " + xhr.status, remove: true });
                    return;
                }

                if (xhr.status < 200 || xhr.status >= 300) {
                    reject("HTTP Error: " + xhr.status);
                    return;
                }

                const json = JSON.parse(xhr.responseText);

                if (!json || typeof json.url != "string") {
                    reject("Invalid JSON: " + xhr.responseText);
                    return;
                }

                resolve(`${process.env.REACT_APP_CDN}assets/${json.url}`);
            };

            xhr.onerror = () => {
                reject({ message: "Image upload failed", remove: true });
                if (failure && typeof failure === "function") {
                    failure("Image upload failed");
                }
            };

            xhr.send(formData);
        });
    };

    const uploadProps: any = {
        name: "file",
        customRequest: async (options: any) => {
            const { file, onSuccess, onError, onProgress } = options;

            try {
                const blobInfo = {
                    blob: () => file,
                    filename: () => file.name,
                };
                const progress = (percent: number) => onProgress({ percent });
                const url = await handleImageUpload(blobInfo, progress, onError);
                onSuccess({ url });
            } catch (error) {
                onError(error);
            }
        },
        maxCount: 1,
        accept: "image/*",
        headers: {
            Authorization: `${type} ${token}`,
        },
        fileList,
        showUploadList: false,
        beforeUpload: (file: RcFile) => {
            const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
            if (!isJpgOrPng) {
                message.error("Chỉ hỗ trợ định dạng JPG hoặc PNG");
            }
            const thumbnail = document.getElementById("thumbnail") as HTMLImageElement;
            thumbnail.src = URL.createObjectURL(file);
            return isJpgOrPng;
        },
        onChange: async (info: UploadChangeParam) => {
            setFileList(info.fileList);
            if (info.file.response && info.file.status === "done") {
                showToast({ ...TOAST_UPLOAD_SUCCESS });
                setFileList([info.file]);
                setThumbnail && setThumbnail(info.file.response.url);
            }
            if (info.file.status === "removed") {
                showToast({ ...TOAST_DELETE_SUCCESS });
                setFileList([]);
            }
            if (info.file.status === "error") {
                showToast({ ...TOAST_UPLOAD_ERROR });
            }
        },
    };

    useEffect(() => {
        const thumbnailHtml = document.getElementById("thumbnail") as HTMLImageElement;

        thumbnailHtml.src = editThumbnail;
    }, [editThumbnail]);

    return (
        <div className="form-group">
            <div className="frame-upload">
                <img
                    className="img-fluid"
                    src={blankImage}
                    alt=""
                    id="thumbnail"
                />
            </div>
            <Upload.Dragger {...uploadProps}>
                <div className="upload-btn-wrapper">
                    <button className="btn">
                        <i className="bi-upload"></i>
                        Tải ảnh thumbnail
                    </button>
                </div>
            </Upload.Dragger>
        </div>
    );
};

export default UploadImg;
