import blankImage from "@assets/images/blank.png";
import { useToast } from "@components/toast/ToastProvider";
import { TOAST_DELETE_SUCCESS, TOAST_UPLOAD_ERROR, TOAST_UPLOAD_SUCCESS } from "@consts";
import store from "@redux/store";
import { message, Upload } from "antd";
import { RcFile, UploadFile } from "antd/es/upload";
import { UploadChangeParam } from "antd/lib/upload";
import { useEffect, useState } from "react";

interface Props {
    setThumbnail: (thumbnail: any) => void;
    editThumbnail: any;
}

const UploadImg = (props: Props) => {
    const { setThumbnail, editThumbnail } = props;
    const { showToast } = useToast();

    const token = store.getState().auth.accessToken;
    const type = store.getState().auth.type;

    const [fileList, setFileList] = useState<UploadFile[]>([]);

    const uploadProps: any = {
        name: "files",
        action: `${process.env.REACT_APP_HOST}/api/school/images`,
        method: "POST",
        maxCount: 1,
        accept: "image/*",
        headers: {
            Authorization: `${type} ${token}`,
        },
        fileList,
        showUploadList: false,
        beforeUpload: (file: RcFile) => {
            const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
            if (!isJpgOrPng) {
                message.error("Chỉ hỗ trợ định dạng JPG hoặc PNG");
            }
            const thumbnail = document.getElementById("thumbnail") as HTMLImageElement;
            thumbnail.src = URL.createObjectURL(file);
            return isJpgOrPng;
        },
        onChange: async (info: UploadChangeParam) => {
            setFileList(info.fileList);
            if (info.file.response && info.file.status === "done") {
                showToast({ ...TOAST_UPLOAD_SUCCESS });
                setFileList([info.file]);
                setThumbnail && setThumbnail(info.file.response && info.file.response[0].url);
            }
            if (info.file.status === "removed") {
                showToast({ ...TOAST_DELETE_SUCCESS });
                setFileList([]);
            }
            if (info.file.status === "error") {
                showToast({ ...TOAST_UPLOAD_ERROR });
            }
        },
    };

    useEffect(() => {
        const thumbnailHtml = document.getElementById("thumbnail") as HTMLImageElement;
        thumbnailHtml.src = "" + process.env.REACT_APP_CDN + editThumbnail;
    }, [editThumbnail]);

    return (
        <div className="form-group">
            <div className="frame-upload">
                <img
                    className="img-fluid"
                    src={blankImage}
                    alt=""
                    id="thumbnail"
                />
            </div>
            <Upload.Dragger {...uploadProps}>
                <div className="upload-btn-wrapper">
                    <button className="btn">
                        <i className="bi-upload"></i>
                        Tải ảnh thumbnail
                    </button>
                </div>
            </Upload.Dragger>
        </div>
    );
};

export default UploadImg;
