import { createApi } from "@reduxjs/toolkit/query/react";
import { customFetchBase } from "../customFetchBase";

const BASE = "/api/partner";

export const adminPartner = createApi({
    reducerPath: "adminPartner",
    keepUnusedDataFor: 30 * 60,
    tagTypes: ["Partner"],
    baseQuery: customFetchBase,
    endpoints: (builder) => ({
        getListPartner: builder.query<Array<IPartner>, void>({
            query: () => ({
                url: `${BASE}`,
                method: "GET",
            }),
            transformErrorResponse(response, meta, arg) {
                return response.data;
            },
            providesTags: (result, error, page) =>
                result
                    ? [
                          ...result.map(({ id }) => ({ type: "Partner" as const, id })),
                          { type: "Partner", id: "Partner-LIST" },
                      ]
                    : [{ type: "Partner", id: "Partner-LIST" }],
        }),
        getPartnerById: builder.query<IPartner, { id: string }>({
            query: (param) => ({
                url: `${BASE}/${param.id}`,
                method: "GET",
            }),
            transformErrorResponse(response, meta, arg) {
                return response.data;
            },
        }),
        createdPartner: builder.mutation<any, any>({
            query: (params) => ({
                url: `${BASE}`,
                method: "POST",
                body: params,
            }),
            transformErrorResponse(response, meta, arg) {
                return response.data;
            },
            invalidatesTags: ["Partner"],
        }),
        updatedPartner: builder.mutation<any, IPartner>({
            query: (params) => ({
                url: `${BASE}/${params.id}`,
                method: "PATCH",
                body: params,
            }),
            transformErrorResponse(response, meta, arg) {
                return response.data;
            },
            invalidatesTags: ["Partner"],
        }),
        removePartner: builder.mutation<any, { id: string }>({
            query: (params) => ({
                url: `${BASE}/${params.id}`,
                method: "DELETE",
            }),
            transformErrorResponse(response, meta, arg) {
                return response.data;
            },
            invalidatesTags: ["Partner"],
        }),
    }),
});

export const {
    useCreatedPartnerMutation,
    useGetListPartnerQuery,
    useGetPartnerByIdQuery,
    useLazyGetListPartnerQuery,
    useLazyGetPartnerByIdQuery,
    useUpdatedPartnerMutation,
    useRemovePartnerMutation,
} = adminPartner;
