import { useToast } from "@components/toast/ToastProvider";
import { TOAST_CREATE_ERROR, TOAST_CREATE_SUCCESS, TOAST_UPDATE_ERROR, TOAST_UPDATE_SUCCESS } from "@consts";
import { useCreatedPartnerMutation, useUpdatedPartnerMutation } from "@redux/queries/admin/admin.partner";
import { Col, Form, Input, InputNumber, Modal, Row, Radio } from "antd";
import { useEffect, useState } from "react";
import UploadImg from "./UploadImg";
import { RadioChangeEvent } from "antd/lib";

interface Props {
    openHandleForm: boolean;
    setOpenHandleForm: (visible: boolean) => void;
    dataEdit?: any;
    setDataEdit?: (data: any) => void;
}

const HandleForm = (props: Props) => {
    const { openHandleForm, setOpenHandleForm, dataEdit, setDataEdit } = props;
    const [form] = Form.useForm();

    const [thumbnail, setThumbnail] = useState<any>(null);
    const [editThumbnail, setEditThumbnail] = useState<any>(null);
    const [status, setStatus] = useState<boolean>(true);

    const [createPartner] = useCreatedPartnerMutation();
    const [updatePartner] = useUpdatedPartnerMutation();

    const { showToast } = useToast();

    const options = [
        { label: "Active", value: true },
        { label: "Pending", value: false },
    ];

    const handleStatus = ({ target: { value } }: RadioChangeEvent) => {
        setStatus(value);
    };

    const onFinish = async (values: any) => {
        const result = dataEdit
            ? await updatePartner({
                  id: dataEdit.id,
                  image: thumbnail || editThumbnail,
                  status: status,
                  ...values,
              })
            : await createPartner({
                  ...values,
                  image: thumbnail || editThumbnail,
                  status: status,
              });

        if ("error" in result) {
            dataEdit ? showToast(TOAST_UPDATE_ERROR) : showToast(TOAST_CREATE_ERROR);
        }
        if ("data" in result) {
            dataEdit ? showToast(TOAST_UPDATE_SUCCESS) : showToast(TOAST_CREATE_SUCCESS);
            form.resetFields();
            setDataEdit && setDataEdit(null);
            setOpenHandleForm(false);
        }
    };

    useEffect(() => {
        if (dataEdit) {
            form.setFieldsValue(dataEdit);
            setEditThumbnail(dataEdit.image);
        } else {
            form.resetFields();
        }
    }, [dataEdit]);

    return (
        <Form
            form={form}
            onFinish={onFinish}
        >
            <Modal
                open={openHandleForm}
                onCancel={() => {
                    form.resetFields();
                    setOpenHandleForm(false);
                    setDataEdit && setDataEdit(null);
                    setThumbnail(null);
                    setEditThumbnail(null);
                }}
                title={dataEdit ? "Update Partner" : "Add Partner"}
                okText={dataEdit ? "Update" : "Add"}
                onOk={() => form.submit()}
                width={1000}
            >
                <div className="body-component">
                    <Row gutter={24}>
                        <Col span={12}>
                            <div className="form-group">
                                <div className="form-floating">
                                    <Form.Item
                                        name="name"
                                        className="form-floating"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Thông tin bắt buộc",
                                            },
                                        ]}
                                    >
                                        <Input
                                            type="text"
                                            className="form-floating no-icon"
                                            placeholder=""
                                        />
                                    </Form.Item>
                                    <label>Tên partner</label>
                                </div>
                            </div>
                        </Col>
                        <Col span={12}>
                            <div className="form-group">
                                <div className="form-floating">
                                    <Form.Item
                                        name="directUrl"
                                        className="form-floating"
                                        rules={[{ type: "url", warningOnly: true, message: "Định dạng đường dẫn sai" }]}
                                    >
                                        <Input
                                            type="text"
                                            className="form-floating no-icon"
                                            placeholder=""
                                        />
                                    </Form.Item>
                                    <label>Đường đẫn</label>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <div className="form-group">
                        <div className="form-floating">
                            <Form.Item
                                name="description"
                                // className="form-floating"
                                // rules={[
                                //     {
                                //         required: true,
                                //         message: "Thông tin bắt buộc",
                                //     },
                                // ]}
                            >
                                <Input.TextArea
                                    style={{ height: 100 }}
                                    placeholder=""
                                />
                            </Form.Item>
                            <label>Miêu tả</label>
                        </div>
                    </div>

                    <UploadImg
                        setThumbnail={(thumbnail) => setThumbnail(thumbnail)}
                        editThumbnail={editThumbnail}
                    />

                    <Row gutter={24}>
                        <Col span={12}>
                            <div className="form-group">
                                <div className="form-floating">
                                    <Form.Item
                                        name="order"
                                        className="form-floating"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Thông tin bắt buộc",
                                            },
                                        ]}
                                    >
                                        <InputNumber
                                            type="number"
                                            min={0}
                                            size="large"
                                            style={{ width: "100%" }}
                                            onWheel={(e) => e.currentTarget.blur()}
                                            className="form-floating no-icon"
                                            placeholder=""
                                        />
                                    </Form.Item>
                                    <label>Order</label>
                                </div>
                            </div>
                        </Col>
                        <Col span={12}>
                            <div className="form-group">
                                <div className="form-floating">
                                    <Form.Item
                                        name="status"
                                        className="form-floating"
                                        // rules={[
                                        //     {
                                        //         required: true,
                                        //         message: "Thông tin bắt buộc",
                                        //     },
                                        // ]}
                                    >
                                        <Radio.Group
                                            options={options}
                                            onChange={handleStatus}
                                            defaultValue={true}
                                            value={status}
                                            optionType="button"
                                            buttonStyle="solid"
                                            className="mt-2"
                                        />
                                    </Form.Item>
                                    <label>Status</label>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Modal>
        </Form>
    );
};

export default HandleForm;
