import menuBar from "@assets/images/icons/menu-bar.svg";
import logo from "@assets/images/logo.png";
import { listRoleAdmins } from "@consts";
import { authSelector } from "@redux/slices/auth.slice";
import { checkPermission } from "@utils/common";
import { format } from "date-fns";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";

const Sidebar = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { accessToken, type, user } = useSelector(authSelector);
    const isAdmin = user?.isAdmin || (user?.role && listRoleAdmins.includes(user?.role));
    const isLogin = !!(accessToken && type && user);

    const checkActiveNavItem = (path: string, className: string, tag?: string) => {
        if (tag) {
            return location.hash.includes(tag) ? `${className} active` : className;
        }

        return location.pathname.includes(path) ? `${className} active` : className;
    };
    const checkActiveSubNavItem = (path: string, className: string) => {
        return location.pathname === path ? `${className} active` : className;
    };

    const customSubNavItem = (path: string, name: string, permission?: string) => {
        return !permission || checkPermission(permission) ? (
            <li className={checkActiveSubNavItem(path, "")}>
                <Link to={path}>{name}</Link>
            </li>
        ) : null;
    };

    const customSubNavItemWithIcon = (path: string, name: string, icon: string, permission?: string) => {
        const handleClick = () => {
            closeMobileOffcanvas();
            // Thêm bất kỳ xử lý khác nếu cần
        };

        return !permission || checkPermission(permission) ? (
            <li className={checkActiveSubNavItem(path, "")}>
                <Link
                    to={path}
                    onClick={handleClick}
                >
                    <i className={icon}></i>
                    {name}
                </Link>
            </li>
        ) : null;
    };

    const closeMobileOffcanvas = () => {
        const mobileOffcanvas = document.getElementById("navbar-main");
        const screenDarken = document.querySelector(".screen-darken");
        const bodyOffcanvas = document.querySelector(".offcanvas-active");

        if (bodyOffcanvas) {
            bodyOffcanvas.classList.remove("offcanvas-active");
        }

        if (mobileOffcanvas) {
            mobileOffcanvas.classList.remove("show");
        }

        if (screenDarken) {
            screenDarken.classList.remove("active");
        }
    };

    return (
        <header>
            <button
                id="btn-mobile"
                data-trigger="navbar-main"
                className="d-lg-none"
                type="button"
            >
                <img
                    src={menuBar}
                    alt="icon-menu"
                />
            </button>
            <nav
                id="navbar-main"
                className="mobile-offcanvas navbar-light bg-nav"
            >
                {" "}
                {/* If this is a user page, you add class: .navbar-light */}
                <button className="btn-close float-end">
                    <i className="bi-x" />
                </button>
                <Link
                    className="navbar-brand"
                    to="/"
                >
                    <img
                        src={logo}
                        alt="logo"
                    />
                </Link>
                <div className="time-box">
                    <i className="bi-calendar" />
                    <time>
                        <span id="timing">{format(Date.now(), "K:mm:ss")}</span>
                        {new Intl.DateTimeFormat("vi-VN", {
                            weekday: "long",
                            year: "numeric",
                            month: "numeric",
                            day: "numeric",
                        }).format(new Date())}
                    </time>
                </div>
                <ul className="navbar-nav">
                    {isAdmin && (
                        <>
                            <li className={checkActiveNavItem("/dashboard", "nav-item")}>
                                <Link
                                    className="nav-link"
                                    data-bs-toggle="collapse"
                                    to="#statistic"
                                    role="button"
                                    aria-expanded="false"
                                    aria-controls="statistic"
                                >
                                    <i className="bi-file-bar-graph"></i> Thống kê
                                    <span className="bi-chevron-right" />
                                </Link>
                                <ul
                                    id="statistic"
                                    className="collapse show"
                                >
                                    {customSubNavItemWithIcon("/dashboard/common", "Tổng hợp", "bi-file-earmark-ruled")}
                                    {customSubNavItemWithIcon("/dashboard/apply", "Apply", "bi-file-earmark-text")}
                                    <li className="nav-item">
                                        <Link
                                            data-bs-toggle="collapse"
                                            to="#payment-sub-menu"
                                            role="button"
                                            aria-expanded="false"
                                            aria-controls="payment-sub-menu"
                                            className={checkActiveNavItem("/datas/payment", "")}
                                        >
                                            <i className="bi-cash-coin"></i> Kinh doanh
                                            <span className="bi-chevron-right"></span>
                                        </Link>
                                        <ul
                                            id="payment-sub-menu"
                                            className="collapse"
                                        >
                                            {customSubNavItem("/dashboard/payment-method", "Phương thức thanh toán")}
                                            {customSubNavItem(
                                                "/dashboard/payment",
                                                "Quản lý thanh toán",
                                                "api/payment/paginate-get"
                                            )}
                                        </ul>
                                    </li>
                                    {customSubNavItemWithIcon(
                                        "/dashboard/revenue",
                                        "Tài chính",
                                        "bi-journal-bookmark-fill"
                                    )}
                                </ul>
                            </li>
                            <li className={checkActiveNavItem("/users", "nav-item")}>
                                <Link
                                    className="nav-link"
                                    data-bs-toggle="collapse"
                                    to="#manage-human"
                                    role="button"
                                    aria-expanded="false"
                                    aria-controls="manage-human"
                                >
                                    <i className="bi-file-earmark-person"></i> Quản lý con người
                                    <span className="bi-chevron-right" />
                                </Link>
                                <ul
                                    id="manage-human"
                                    className="collapse"
                                >
                                    {customSubNavItemWithIcon(
                                        "/users",
                                        "Danh sách",
                                        "bi-person",
                                        "api/user/paginate-get"
                                    )}
                                    {customSubNavItemWithIcon(
                                        "/users/customer",
                                        "Khách hàng",
                                        "bi-file-person-fill",
                                        "api/user/customer/paginate-get"
                                    )}
                                    {customSubNavItemWithIcon(
                                        "/users/employee",
                                        "Nhân viên",
                                        "bi-file-person",
                                        "api/user/employee/paginate-get"
                                    )}
                                    {customSubNavItemWithIcon(
                                        "/users/role",
                                        "Phân quyền",
                                        "bi-person-lines-fill",
                                        "api/role/paginate-get"
                                    )}
                                    {(checkPermission("api/team/paginate-get") ||
                                        checkPermission("api/user/team/paginate-get")) && (
                                        <li className="nav-item">
                                            <Link
                                                data-bs-toggle="collapse"
                                                to="#team-sub-menu"
                                                role="button"
                                                aria-expanded="false"
                                                aria-controls="team-sub-menu"
                                                className={checkActiveNavItem("/users/team", "")}
                                            >
                                                <i className="bi-people"></i> Team
                                                <span className="bi-chevron-right"></span>
                                            </Link>
                                            <ul
                                                id="team-sub-menu"
                                                className="collapse"
                                            >
                                                {customSubNavItem("/users/team", "Danh sách", "api/team/paginate-get")}
                                                {customSubNavItem(
                                                    "/users/team-user",
                                                    "Thành viên",
                                                    "api/user/team/paginate-get"
                                                )}
                                            </ul>
                                        </li>
                                    )}
                                    {/* <li>
                                        <Link to="#">
                                            <i className="bi-file-earmark-person-fill"></i> Đối tác{" "}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#">
                                            <i className="bi-file-earmark-spreadsheet"></i> Tệp khách hàng{" "}
                                        </Link>
                                    </li> */}
                                </ul>
                            </li>
                            <li className={checkActiveNavItem("/datas", "nav-item")}>
                                <Link
                                    className="nav-link"
                                    data-bs-toggle="collapse"
                                    to="#manage-data"
                                    role="button"
                                    aria-expanded="false"
                                    aria-controls="manage-data"
                                >
                                    <i className="bi-layout-text-sidebar-reverse" /> Quản lý dữ liệu
                                    <span className="bi-chevron-right" />
                                </Link>
                                <ul
                                    id="manage-data"
                                    className="collapse"
                                >
                                    {customSubNavItemWithIcon("/datas/ticket", "Ticket", "bi-ticket-perforated")}
                                    {customSubNavItemWithIcon(
                                        "/datas/deposit",
                                        "Nạp tiền",
                                        "bi-coin",
                                        "api/deposit/paginate-get"
                                    )}
                                    {customSubNavItemWithIcon(
                                        "/datas/apply-passed",
                                        "Báo đỗ",
                                        "bi-megaphone",
                                        "api/apply/paginate-get"
                                    )}
                                    <li>
                                        <Link to="#">
                                            <i className="bi-file-earmark-text"></i> File{" "}
                                        </Link>
                                    </li>
                                    {customSubNavItemWithIcon(
                                        "/datas/approve-blog",
                                        "Blog",
                                        "bi-file-earmark-post-fill"
                                    )}
                                    {customSubNavItemWithIcon("/datas/category-blog", "Danh mục", "bi-card-list")}
                                    {customSubNavItemWithIcon(
                                        "/datas/notification",
                                        "Thông báo",
                                        "bi-bell",
                                        "api/notification/paginate-get"
                                    )}
                                </ul>
                            </li>
                            <li className={checkActiveNavItem("/admin", "nav-item")}>
                                <Link
                                    className="nav-link"
                                    data-bs-toggle="collapse"
                                    to="#manage-system"
                                    role="button"
                                    aria-expanded="false"
                                    aria-controls="manage-system"
                                >
                                    <i className="bi-pc-horizontal"></i>
                                    Quản lý hệ thống
                                    <span className="bi-chevron-right"></span>
                                </Link>
                                <ul
                                    id="manage-system"
                                    className="collapse"
                                >
                                    <li className="nav-item">
                                        <Link
                                            data-bs-toggle="collapse"
                                            to="#school-sub-menu"
                                            role="button"
                                            aria-expanded="false"
                                            aria-controls="school-sub-menu"
                                            className={checkActiveNavItem("/admin/school", "")}
                                        >
                                            <i className="bi-bank"></i> Trường học
                                            <span className="bi-chevron-right"></span>
                                        </Link>
                                        <ul
                                            id="school-sub-menu"
                                            className="collapse"
                                        >
                                            {customSubNavItem("/admin/school", "Danh sách")}
                                            {customSubNavItem("/admin/school-category", "Danh mục")}
                                            {/* {customSubNavItem("/admin/school-news", "Tin tức")} */}
                                            {customSubNavItem("/admin/school-service", "Dịch vụ")}
                                            {customSubNavItem(
                                                "/admin/school-faq",
                                                "Câu hỏi thường gặp",
                                                "api/school/faq/paginate-get"
                                            )}
                                            {customSubNavItem(
                                                "/admin/school-review",
                                                "Review",
                                                "api/school/review/paginate-get"
                                            )}
                                            {customSubNavItem("/admin/school-specialized", "Chuyên ngành")}
                                        </ul>
                                    </li>
                                    {(checkPermission("api/apply/paginate-get") ||
                                        checkPermission("api/step-get") ||
                                        checkPermission("api/apply/task/paginate-get") ||
                                        checkPermission("api/apply/process/paginate-get") ||
                                        checkPermission("api/apply/payment/paginate-get")) && (
                                        <li className="nav-item">
                                            <Link
                                                data-bs-toggle="collapse"
                                                to="#apply-sub-menu"
                                                role="button"
                                                aria-expanded="false"
                                                aria-controls="new-sub-menu"
                                            >
                                                <i className="bi-file-earmark-text"></i> Apply
                                                <span className="bi-chevron-right"></span>
                                            </Link>
                                            <ul
                                                id="apply-sub-menu"
                                                className="collapse"
                                            >
                                                {customSubNavItem(
                                                    "/admin/apply",
                                                    "Danh sách",
                                                    "api/apply/paginate-get"
                                                )}
                                                {customSubNavItem("/admin/step", "Step", "api/step-get")}
                                                {customSubNavItem(
                                                    "/admin/apply-task",
                                                    "Apply Task",
                                                    "api/apply/task/paginate-get"
                                                )}
                                                {customSubNavItem(
                                                    "/admin/apply-process",
                                                    "Apply Process",
                                                    "api/apply/process/paginate-get"
                                                )}
                                                {customSubNavItem(
                                                    "/admin/apply-payment",
                                                    "Apply Payment",
                                                    "api/apply/payment/paginate-get"
                                                )}
                                            </ul>
                                        </li>
                                    )}
                                    <li className="nav-item">
                                        <Link
                                            data-bs-toggle="collapse"
                                            to="#scholarship-sub-menu"
                                            role="button"
                                            aria-expanded="false"
                                            aria-controls="new-sub-menu"
                                        >
                                            <i className="bi-mortarboard"></i>Học bổng
                                            <span className="bi-chevron-right"></span>
                                        </Link>
                                        <ul
                                            id="scholarship-sub-menu"
                                            className="collapse"
                                        >
                                            {customSubNavItem("/admin/scholarship", "Danh sách")}
                                            {customSubNavItem("/admin/scholarship-type", "Loại học bổng")}
                                            {customSubNavItem("/admin/scholarship-policy", "Cơ cấu học bổng")}
                                        </ul>
                                    </li>
                                    {customSubNavItemWithIcon("/admin/training", "Hệ đào tạo", "bi-file-medical")}
                                    {customSubNavItemWithIcon("/admin/majors", "Ngành học", "bi-file-medical")}
                                    {customSubNavItemWithIcon("/admin/specialized", "Chuyên ngành", "bi-file-medical")}
                                    {customSubNavItemWithIcon(
                                        "/admin/school-news",
                                        "Tin tức",
                                        "bi-file-post",
                                        "api/school/news/paginate-get"
                                    )}
                                    {customSubNavItemWithIcon("/admin/area", "Khu vực", "bi-pin-map-fill")}
                                    {customSubNavItemWithIcon("/admin/city", "Thành phố", "bi-pin-map-fill")}
                                    {customSubNavItemWithIcon("/admin/project", "Dự án", "bi-file-earmark-ruled")}
                                    {customSubNavItemWithIcon(
                                        "/admin/consultation",
                                        "Danh sách tư vấn",
                                        "bi-file-earmark-ruled",
                                        "api/consultation/paginate-get"
                                    )}
                                    {customSubNavItemWithIcon(
                                        "/admin/partner",
                                        "Danh sách partner",
                                        "bi-file-earmark-ruled"
                                    )}
                                    {customSubNavItemWithIcon("/admin/ads", "Ads", "bi-file-medical")}
                                </ul>
                            </li>
                        </>
                    )}

                    {isLogin && !isAdmin && (
                        <>
                            <li className={checkActiveNavItem("/user/profile", "nav-item", "#profile-tab")}>
                                <Link
                                    className="nav-link"
                                    to="/user/profile#profile-tab"
                                    onClick={closeMobileOffcanvas}
                                >
                                    <i className="bi-journal-bookmark-fill"></i> Hồ sơ cá nhân
                                </Link>
                            </li>

                            <li className={checkActiveNavItem("/user/profile", "nav-item", "#history-payment-tab")}>
                                <Link
                                    className="nav-link"
                                    to="/user/profile#history-payment-tab"
                                    onClick={closeMobileOffcanvas}
                                >
                                    <i className="bi-cash"></i> Thanh toán
                                </Link>
                            </li>

                            <li className={checkActiveNavItem("/user/profile", "nav-item", "#history-deposit-tab")}>
                                <Link
                                    className="nav-link"
                                    to="/user/profile#history-deposit-tab"
                                    onClick={closeMobileOffcanvas}
                                >
                                    <i className="bi-cash-coin"></i> Nạp tiền
                                </Link>
                            </li>

                            <li className={checkActiveNavItem("/user/profile", "nav-item", "#history-review-tab")}>
                                <Link
                                    className="nav-link"
                                    to="/user/profile#history-review-tab"
                                    onClick={closeMobileOffcanvas}
                                >
                                    <i className="bi-chat-dots"></i> Lịch sử Review
                                </Link>
                            </li>

                            <li className={checkActiveNavItem("/user/profile", "nav-item", "#ticket-tab")}>
                                <Link
                                    className="nav-link"
                                    to="/user/profile#ticket-tab"
                                    onClick={closeMobileOffcanvas}
                                >
                                    <i className="bi-ticket-detailed"></i> Phiếu hỗ trợ
                                </Link>
                            </li>

                            <li className={checkActiveNavItem("/user/profile", "nav-item", "#process-apply-tab")}>
                                <Link
                                    className="nav-link"
                                    to="/user/profile#process-apply-tab"
                                    onClick={closeMobileOffcanvas}
                                >
                                    <i className="bi-diagram-3"></i> Danh sách Apply
                                </Link>
                            </li>
                        </>
                    )}

                    <li className={checkActiveNavItem("/news", "nav-item")}>
                        <Link
                            className="nav-link"
                            to="/news"
                        >
                            <i className="bi-newspaper"></i> Tin tức
                        </Link>
                    </li>

                    <li className={checkActiveNavItem("pro-tool", "nav-item")}>
                        <Link
                            className="nav-link"
                            data-bs-toggle="collapse"
                            to="#tool"
                            role="button"
                            aria-expanded="false"
                            aria-controls="tool"
                        >
                            <i className="bi-magic"></i>
                            Công cụ PRO
                            <span className="bi-chevron-right" />
                        </Link>
                        <ul
                            id="tool"
                            className="collapse"
                        >
                            {customSubNavItemWithIcon("/pro-tool/suggest-schools", "Đề xuất Trường", "bi-award")}
                            {customSubNavItemWithIcon("/pro-tool/schools", "Tra cứu trường", "bi-bank2")}
                            {customSubNavItemWithIcon("/pro-tool/compare", "So sánh trường", "bi-arrow-left-right")}
                            {customSubNavItemWithIcon("/pro-tool/scholarship", "Tra cứu Học bổng", "bi-award")}
                            {customSubNavItemWithIcon("/pro-tool/specialized", "Tra chuyên ngành", "bi-bookmark-plus")}
                            {customSubNavItemWithIcon(
                                "/pro-tool/service",
                                "Tra cứu thông tin tuyển sinh",
                                "bi-info-circle"
                            )}
                            {/* <li>
                                <Link to="#">
                                    {" "}
                                    <i className="bi-award"></i>Tra cứu Học bổng{" "}
                                </Link>
                            </li>
                            <li>
                                <Link to="#">
                                    <i className="bi-bookmark-plus"></i> Tra chuyên Ngành{" "}
                                </Link>
                            </li> */}
                            {/* <li>
                                <Link to="#">
                                    <i className="bi-book"></i> Tra cứu Môn học{" "}
                                </Link>
                            </li>
                            <li>
                                <Link to="#">
                                    {" "}
                                    <i className="bi-bar-chart-steps"></i>Việc cần làm{" "}
                                </Link>
                            </li> */}
                        </ul>
                    </li>

                    {isLogin && !isAdmin && (
                        <>
                            <li className={checkActiveNavItem("/create-ticket", "nav-item")}>
                                <Link
                                    className="nav-link"
                                    to="/create-ticket"
                                >
                                    <i className="bi-send-plus"></i> Gửi phiếu hỗ trợ
                                </Link>
                            </li>
                        </>
                    )}
                </ul>
            </nav>
        </header>
    );
};

export default Sidebar;
// function close_offcanvas_custome() {
//     throw new Error("Function not implemented.");
// }
