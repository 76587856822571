import { createApi } from "@reduxjs/toolkit/query/react";
import { customFetchBase } from "../customFetchBase";
import _ from "lodash";

const BASE = "/api/ads";

export const adminAds = createApi({
    reducerPath: "adminAds",
    keepUnusedDataFor: 30 * 60,
    tagTypes: ["Ads"],
    baseQuery: customFetchBase,
    endpoints: (builder) => ({
        getListAds: builder.query<Array<IAds>, void>({
            query: (query) => ({
                url: `${BASE}`,
                method: "GET",
            }),
            transformErrorResponse(response, meta, arg) {
                return response.data;
            },
            providesTags: (result, error, page) =>
                result
                    ? [...result.map(({ id }) => ({ type: "Ads" as const, id })), { type: "Ads", id: "Ads-LIST" }]
                    : [{ type: "Ads", id: "Ads-LIST" }],
        }),
        getAdsById: builder.query<IAds, { id: string }>({
            query: (query) => ({
                url: `${BASE}/${query.id}`,
                method: "GET",
            }),
            transformErrorResponse(response, meta, arg) {
                return response.data;
            },
        }),
        createAds: builder.mutation<any, any>({
            query: (params) => ({
                url: `${BASE}`,
                method: "POST",
                body: params,
            }),
            transformErrorResponse(response, meta, arg) {
                return response.data;
            },
            invalidatesTags: ["Ads"],
        }),
        updateAds: builder.mutation<any, IAds>({
            query: (params) => ({
                url: `${BASE}/${params.id}`,
                method: "PATCH",
                body: params,
            }),
            transformErrorResponse(response, meta, arg) {
                return response.data;
            },
            invalidatesTags: ["Ads"],
        }),
        removeAds: builder.mutation<any, { id: string }>({
            query: (params) => ({
                url: `${BASE}/${params.id}`,
                method: "DELETE",
            }),
            transformErrorResponse(response, meta, arg) {
                return response.data;
            },
            invalidatesTags: ["Ads"],
        }),
    }),
});

export const {
    useGetListAdsQuery,
    useGetAdsByIdQuery,
    useCreateAdsMutation,
    useUpdateAdsMutation,
    useRemoveAdsMutation,
    useLazyGetAdsByIdQuery,
    useLazyGetListAdsQuery,
} = adminAds;
