import { useToast } from "@components/toast/ToastProvider";
import { TOAST_DELETE_ERROR, TOAST_DELETE_SUCCESS } from "@consts";
import { useGetListPartnerQuery, useRemovePartnerMutation } from "@redux/queries/admin/admin.partner";
import { Button, Modal, Table, Tag } from "antd";
import React, { useState } from "react";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { TypeStatus } from "./TypeStatus";
import HandleForm from "./components/HandleForm";

const Partner = () => {
    const { data, isLoading, isFetching } = useGetListPartnerQuery();
    const [deletePartner] = useRemovePartnerMutation();

    const [modal, contextHolder] = Modal.useModal();

    const [dataEdit, setDataEdit] = useState<any>(null);

    const [openHandleForm, setOpenHandleForm] = useState(false);

    const { showToast } = useToast();

    const handleDelete = async (id: string) => {
        const result = await deletePartner({
            id: id,
        });

        if ("error" in result) {
            showToast(TOAST_DELETE_ERROR);
        }

        if ("data" in result) {
            showToast(TOAST_DELETE_SUCCESS);
        }
    };

    const confirmDeleteRecord = (id: string) => {
        modal.confirm({
            title: "Cảnh báo",
            icon: <ExclamationCircleOutlined />,
            content: "Bạn có chắc chắn muốn xóa bản ghi này?",
            okText: "Xóa",
            cancelText: "Hủy",
            onOk() {
                handleDelete(id);
            },
            centered: true,
        });
    };

    const columns = [
        {
            title: <div className="text-title-table">Tên partner</div>,
            key: "name",
            dataIndex: "name",
        },
        {
            title: <div className="text-title-table">Miêu tả</div>,
            key: "description",
            dataIndex: "description",
            width: 200,

            render: (des: string) => {
                return <div className="">{des}</div>;
            },
        },
        {
            title: <div className="text-title-table">Ảnh</div>,
            key: "image",
            dataIndex: "image",

            render: (image: any) => {
                return (
                    <img
                        width={100}
                        src={image}
                    />
                );
            },
        },
        {
            title: <div className="text-title-table">Link</div>,
            key: "directUrl",
            width: 200,
            dataIndex: "directUrl",
            render: (link: string) => {
                return <Link to={link}>{link}</Link>;
            },
        },
        {
            title: <div className="text-title-table">Order</div>,
            key: "order",
            dataIndex: "order",
        },
        {
            title: <div className="text-title-table">Trạng thái</div>,
            key: "status",
            dataIndex: "status",
            render: (status: any) => {
                const statusInfo = TypeStatus.find((item) => item.value === status);
                if (statusInfo) {
                    return <Tag color={statusInfo.color}>{statusInfo.label}</Tag>;
                }
            },
        },

        {
            title: <div className="text-title-table">Action</div>,
            width: 120,
            key: "action",
            fixed: "right" as any,
            render: (text: any, record: any) => {
                return (
                    <div
                        className="group-icon-action"
                        key={record.id}
                    >
                        <button
                            type="button"
                            className="text-green"
                            onClick={() => {
                                setDataEdit(record);
                                setOpenHandleForm(true);
                            }}
                        >
                            <i className="bi-pencil-square"></i>
                        </button>
                        <button
                            type="button"
                            className="text-red"
                            onClick={() => confirmDeleteRecord(record.id)}
                        >
                            <i className="bi-trash"></i>
                        </button>
                    </div>
                );
            },
        },
    ];

    return (
        <div className="container-fluid padding0">
            <span className="screen-darken"></span>
            <main>
                <section id="content-main">
                    <div className="box-component">
                        <div className="body-component">
                            <div className="row">
                                <div className="col-12 col-sm-6">
                                    <div className="group-btn">
                                        <Button
                                            type="primary"
                                            onClick={() => setOpenHandleForm(true)}
                                        >
                                            Add New
                                        </Button>
                                    </div>
                                </div>
                            </div>
                            <div className="table-responsive">
                                <Table
                                    columns={columns}
                                    dataSource={(data as any) || []}
                                    pagination={false}
                                    rowKey="id"
                                    loading={isLoading || isFetching}
                                />
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            {contextHolder}
            <HandleForm
                openHandleForm={openHandleForm}
                setOpenHandleForm={(v) => setOpenHandleForm(v)}
                dataEdit={dataEdit}
                setDataEdit={(data) => setDataEdit(data)}
            />
        </div>
    );
};

export default Partner;
